<template>
  <div class="app">
    <div class="header-form">
      <div class="admin_main_block_left">
        <el-form>
          <el-form-item label="所属区域">
            <el-select
              v-model="where.operationAreaCode"
              placeholder="请选择"
              clearable
            >
              <el-option label="全部" value=""></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="投放范围">
            <el-select
              v-model="where.operationAreaCode"
              placeholder="请选择"
              clearable
            >
              <el-option label="全部" value=""></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select
              v-model="where.operationAreaCode"
              placeholder="请选择"
              clearable
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="开启" value="1"></el-option>
              <el-option label="关闭" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关键字">
            <el-input
              v-model="where.keyWord"
              placeholder="请输入订单编号/会员账号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="加入时间">
            <el-date-picker
              v-model="date"
              @change="orderDate" @input="immediUpdate"
              type="daterange"
              range-separator="~"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
                              start-placeholder="开始"
              end-placeholder="截止"
            >
              <i class="el-icon-date"></i>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain icon="el-icon-search" @click="listData()"
              >条件筛选</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="admin_main_block_right">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-button type="primary" @click="add" icon="el-icon-plus"
              >新增</el-button
            >
          </el-col>
          <el-col :span="12">
            <el-button
              type="primary"
              @click="exportData"
              disabled
              icon="el-icon-tickets"
              >导出数据</el-button
            >
          </el-col>
        </el-row>
        <div></div>
      </div>
    </div>
    <el-table :data="list" @selection-change="handleSelectionChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        prop="id"
        label="广告ID"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="adName"
        label="广告位名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="adLocation"
        label="所在位置"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="merchantId"
        label="商家门店ID"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="shopId"
        label="门店名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="businessId"
        label="广告图片"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-image style="height: 50px" :src="getImage(scope.row.adImg)"
            ><div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i></div
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="businessId"
        label="投放范围"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="sort" label="排序" :render-header="renderHeader">
        <template slot-scope="scope">
          <el-input-number
            size="mini"
            placeholder="请输入排序"
            controls-position="right"
            style="width: 70px; text-align: left"
            v-model="list[scope.$index].sort"
            @change="setState(scope.row)"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        prop="startTime"
        label="开始时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="endTime"
        label="结束时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="state" label="状态">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.state"
            @change="setState(scope.row)"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="2"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="操作" fixed="right" width="100px">
        <template slot-scope="scope">
          <el-dropdown>
            <span class="el-dropdown-link"
              >操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                ><el-button
                  type="text"
                  class="el-icon-s-shop"
                  size="medium"
                  @click="
                    $router.push({
                      name: 'advtsadd',
                      query: { id: scope.row.id },
                    })
                  "
                  >详情</el-button
                ></el-dropdown-item
              >
              <el-dropdown-item
                ><el-button
                  type="text"
                  class="el-icon-delete"
                  size="medium"
                  @click="deleteBtn(scope.row)"
                  >删除</el-button
                ></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="admin_table_main_pagination">
      <el-pagination
        @current-change="currentChange"
        @size-change="handleSizeChange"
        background
        :total="totalData"
        :page-size="pageSize"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        layout="sizes, prev, pager, next, total, jumper"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { staticUrl } from "@/plugins/api";

export default {
  components: {},
  props: {
    orderStatus: Number,
  },
  data() {
    return {
      list: [],
      date: "",
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      row: "",
      selectId: "",
      where: {
        adLocation: "",
        adName: "",
        limit: 10,
        page: 1,
        terminalType: "",
      },
    };
  },
  // watch: {
  //   dialogSendVisible(newName, oldName) {
  //     console.log(newName, oldName);
  //   },
  // },
  methods: {
    listData() {
      this.where.pageSize = this.pageSize;
      this.where.limit = this.pageSize;
      this.where.pageNum = this.currentPage;
      this.$post(this.$api.advertList, this.where).then((res) => {
        if (res.code == 1) {
          this.totalData = res.data.total;
          this.list = res.data.records;
        }
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v);
      });
      this.selectId = ids;
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    orderDate(obj) {
      if (obj) {
        this.where.startTime = obj[0];
        this.where.endTime = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.startTime = '';
          this.where.endTime = '';
        }
      });
    },
    exportData() {
      // console.log("exportData");
    },
    add() {
      this.$router.push({ name: "advtsadd" });
    },
    setState(data) {
      this.post(this.$api.advertUpdate, {
        id: data.id,
        state: data.state,
        sort: data.sort,
      });
    },
    deleteBtn(data) {
      this.$confirm("是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.post(this.$api.advertDel, { id: data.id });
          this.listData();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    post(url, data) {
      this.$postFormData(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          this.listData();
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    // render 事件
    renderHeader(h, { column }) {
      let text = "数字越小 排序越高";
      return h("div", [
        h("span", column.label),
        h("el-tooltip", [
          h("i", {
            class: "el-icon-question",
            style: "color:#409eff;margin-left:5px;",
          }),
          h(
            "div",
            {
              slot: "content",
              // style:"width: 200px;"
            },
            text
          ),
        ]),
      ]);
    },
    getImage(val) {
      return staticUrl + val;
    },
  },
  created() {
    this.where.stateType = this.orderStatus == -1 ? "" : this.orderStatus;
    this.listData();
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input-number--mini .el-input__inner {
  padding-left: 2px;
  padding-right: 32px;
}
</style>

